<template>
	<div class="bouquets main-page">
		<section class="bouquets ">
			<Loader v-if="loading" />

			<div v-else class=" row center  text-center">
				<div class="page-title">
					<h3>Ягодные букеты</h3>
				</div>

				<div class=" search">
					<div class="input-field">
						<input id="search" v-model="filterText" type="text" />
						<label class="active" for="search">Поиск</label>
					</div>
				</div>

				<div
					v-for="product in filteredProducts"
					:key="product.id"
					class="product-item col s12 m6 l4 xl3"
					:class="{ productInCart: product.id === productItem.id }"
				>
					<div class="product-card col s12 m6 l4 xl3">
						<div class="img-container" @click="toProduct(product)">
							<img
								:src="product.mainImageSrc"
								class="img responsive-img "
								alt=""
							/>
						</div>

						<div class="actions">
							<div class="card-info ">
								<span class="title">{{ product.title }}</span>
								<span class="price"
									>{{ product.price | currency }}
								</span>
							</div>

							<button
								v-if="
									cart.find(
										(val) => val.productId === product.id,
									)
								"
								class="btn btn-buy btn-flat row waves-effect  waves-light disabled"
								@click.prevent="addProductToCart(product)"
							>
								<i class="material-icons  white-text"
									>check_circle_outline</i
								>
							</button>

							<button
								v-else
								class="btn btn-buy btn-flat row waves-effect waves-light"
								@click.prevent="addProductToCart(product)"
							>
								<i class="material-icons white-text"
									>shopping_cart</i
								>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	data: () => ({
		name: 'bouquets',
		allProducts: [],
		filterText: '',
		categories: [],
		select: null,
		current: null,
		productItem: { id: '' },
	}),
	mounted() {
		this.allProducts = this.$store.getters.getProducts;
	},
	computed: {
		cart() {
			return this.$store.getters.getCart;
		},
		products() {
			return this.allProducts.filter(
				(el) => el.parentCategory.Path === this.$route.path,
			);
		},
		filteredProducts() {
			return this.products.filter((element) =>
				element.title
					.toLowerCase()
					.match(this.filterText.toLowerCase()),
			);
		},

		loading() {
			return this.$store.getters.loading;
		},
	},

	methods: {
		toCart() {
			this.$router.push('/cart');
			window.scroll({
				top: 170,
				left: 0,
				behavior: 'smooth',
			});
		},
		toProduct(p) {
			this.$router.push(`/product/${p.id}`);
			window.scroll({
				top: 170,
				left: 0,
			});
		},

		addProductToCart(product) {
			this.productItem.id = product.id;
			this.$store.commit('addProductToCart', {
				productId: product.id,
				productImage: product.mainImageSrc,
				productTitle: product.title,
				amount: 1,
				price: product.price,
				sum: 1 * product.price,
			});
			this.$addToCart();
		},
	},
};
</script>

<style scoped lang="less">
@mobileMenuBreakpoint: 650px;
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #343434;

.bear-crumbs {
	z-index: 1;
	position: static;
	height: 3rem;
	width: 100%;
}
</style>
